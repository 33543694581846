import React from "react";
import './footer.css';
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(fas, fab)

const Footer = () => (
  <div className="footer">
   <div className="footeritems">
      <p><FontAwesomeIcon icon="phone"/> &nbsp; <a href="tel:4037022466">(403) 702-2466</a> </p>
      <p><FontAwesomeIcon icon="envelope"/> &nbsp; <a href="mailto:redlinedentworks@gmail.com">redlinedentworks@gmail.com </a> </p>
      <p><FontAwesomeIcon icon={["fab", "instagram"]}/> &nbsp; <a href="https://www.instagram.com/redlinedentworks">redlinedentworks </a></p>
   </div>
  </div>
);

export default Footer;
