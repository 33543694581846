import React from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navbar from './components/Navbar/Navbar';
import Footer from './footer';
import { Gallery } from './components/pages/Gallery';
import { Contact } from './components/pages/Contact';
import { Services } from './components/pages/Services';


function App() {
  return (
    <div className="background">
    <>
      <Router>
        <Navbar />
        <div className="pages">
          <Switch>
            <Route exact path='/gallery' component={Gallery}/>
            <Route exact path='/contact' component={Contact}/>
            <Route exact path='/' component={Services}/>
          </Switch>
        </div>
      </Router>
     <Footer/>
    </>
    </div>
  );
}

export default App;
