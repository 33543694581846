import React from "react";
import './Services.css';
import '../../footer.css';

export const Services = () => {
  return (
    <div className="services">
      <img src="images/main.png"></img>
      <p>
          <b>Redline Dentworks</b> is a locally owned, <br/>
          <b>paintless</b> dent removal company. < br/>
          <br/>
          We specialize in repairing vehicle damage <br/>
          while potentially saving you the large cost <br/>
          associated with re-painting your vehicle. <br/>
          <br/>
          We have repaired over <b>1000</b> vehicles <br/>
          with damage such as: <br/>
          &nbsp;&nbsp; hail damage <br/>
          &nbsp;&nbsp; door dents <br/>
          &nbsp;&nbsp; box side dents <br/>
          &nbsp;&nbsp; large oversize dents and more!<br/>
          <br/>
          &#10004; Vale Certified <br/>
          &#10004; Insured <br/>
          &#10004; AMVIC Licensed <br/>
          <br/>
          Redline Dentworks is based in SW Calgary, <br/>
          but offers mobile service and can travel to you. <br/>
          <br/>
          Contact us today for your <u><b>free</b></u> estimate <b><a href="tel:4037022466">403-702-2466</a></b> <br/>
      </p>
    </div>
  );
};
