import React from "react";
import './Gallery.css';
import SimpleImageSlider from "react-simple-image-slider";
import useResizeObserver from "use-resize-observer";

const IMAGES = [
  { url: 'images/1.jpg'},
  { url: 'images/2.jpg'},
  { url: 'images/3.jpg'},
  { url: 'images/4.jpg'},
  { url: 'images/5.jpg'},
  { url: 'images/6.jpg'},
  { url: 'images/7.jpg'},
  { url: 'images/8.jpg'},
  { url: 'images/9.jpg'},
  { url: 'images/10.jpg'},
  { url: 'images/11.jpg'},
  { url: 'images/12.jpg'}
];

export const Gallery = () => {
  return (
    <div className="gallery">
      <div className='slider'>
        <SimpleImageSlider
          className='slider'
          width="var(--wheelwidth)"
          height="var(--wheelheight)"
          images={IMAGES}
          showBullets={false}
          showNavs={true}
          navSize="var(--navsize)"
          />
      </div>
      <div className="text">
        <br/>
        <div className="before">
        <p>BEFORE </p>
        </div>
        <div className="after">
        <p>AFTER </p>
        </div>
        <br/>
      </div>
      <div className="subtext">
        <p>
        <br/>
        check out our instagram page for more &emsp;
        <br/><br/><br/><br/><br/><br/>
        <a href="https://www.instagram.com/redlinedentworks"><b>@redlinedentworks</b> </a>
        </p>
      </div>
    </div>
  );
};
